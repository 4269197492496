import '../css/app.css';
import './bootstrap';
import './darkModeToggle';

import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { createApp, h } from 'vue';

// plugins
import money from 'v-money3';
import PerfectScrollbar from 'vue3-perfect-scrollbar';
import { ZiggyVue } from 'ziggy-js';
import i18n from './i18n';
import('preline');

const appName = import.meta.env.VITE_APP_NAME || 'PurpleWhale';

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({ el, App, props, plugin }) {
        return createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(i18n)
            .use(money)
            .use(PerfectScrollbar)
            .use(ZiggyVue, Ziggy)
            .mount(el);
    },
    progress: {
        color: '#4B5563',
    },
});
