const initTheme = getTheme() || getMediaPreference();
setTheme(initTheme);

function getTheme() {
    return localStorage.getItem('theme');
}

function getMediaPreference() {
    return 'light'; // TODO: Remove this only when dark mode is fully implemented!

    const hasDarkPreference = window.matchMedia('(prefers-color-scheme: dark)').matches;

    if (hasDarkPreference) {
        return 'dark';
    } else {
        return 'light';
    }
}

function setTheme(theme) {
    localStorage.setItem('theme', theme);
    document.documentElement.className = theme;
}

function toggleTheme() {
    const activeTheme = localStorage.getItem('theme');

    if (activeTheme === 'light') {
        setTheme('dark');
    } else {
        setTheme('light');
    }
}

window.addEventListener('keydown', (e) => {
    if (e.ctrlKey && e.altKey && 'd' === e.key) {
        toggleTheme();
    }
});
