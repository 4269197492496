export default {
    "en": {
        "account": {
            "home": "Home",
            "profile": "Profile",
            "qr_code": "Code",
            "settings": "Settings",
            "qr_header": "Earn & save with your code.",
            "qr_description": "Show this QR code to our staff to access your account.",
            "account_information": "Account Information",
            "update_account": "Update your account's profile information",
            "delete_account": "Delete Account",
            "delete_account_disclaimer": "Once your account is deleted, all of its balance and data will be permanently deleted. Before deleting your account, please spend any remaining points ot tokens.",
            "delete_account_confirmation": "Are you sure you want to delete your account?",
            "delete_account_instructions": "Once your account is deleted, all of its balance and data will be permanently deleted. Please enter the 6-digit code sent to your phone to confirm you would like to permanently delete your account."
        },
        "actions": {
            "accept": "Accept",
            "add": "Add",
            "agree": "Agree",
            "apply": "Apply",
            "back": "Back",
            "begin": "Begin",
            "by": "by",
            "cancel": "Cancel",
            "change": "Change",
            "checkout": "Checkout",
            "close": "Close",
            "collapse": "Collapse",
            "confirm": "Confirm",
            "confirmation": {
                "delete": "Are you sure you want to delete? This cannot be undone.",
                "submit": "Are you sure you want to submit?",
                "update": "Are you sure you want to update?"
            },
            "create": "Create",
            "decline": "Decline",
            "delete": "Delete",
            "disable": "Disable",
            "done": "Done",
            "download": "Download",
            "edit": "Edit",
            "enable": "Enable",
            "end": "End",
            "exit": "Exit",
            "expand": "Expand",
            "filter": "Filter",
            "find": "Find",
            "follow": "Follow",
            "go": "Go",
            "help": "Help",
            "hide": "Hide",
            "hide_all": "Hide All",
            "home": "Home",
            "less": "Less",
            "log_in": "Log In",
            "log_out": "Log Out",
            "manage": "Manage",
            "maximize": "Maximize",
            "minimize": "Minimize",
            "more": "More",
            "new": "New",
            "next": "Next",
            "no": "No",
            "no_results": "No results",
            "off": "Off",
            "okay": "Okay",
            "on": "On",
            "open": "Open",
            "other": "Other",
            "pay": "Pay",
            "previous": "Previous",
            "print": "Print",
            "publish": "Publish",
            "recommend": "Recommend",
            "redo": "Redo",
            "register": "Register",
            "remove": "Remove",
            "return": "Return",
            "save": "Save",
            "search": "Search",
            "select": "Select",
            "send": "Send",
            "show": "Show",
            "show_all": "Show All",
            "sign_up": "Sign Up",
            "social_login": "Continue with {provider}",
            "start": "Start",
            "stop": "Stop",
            "submit": "Submit",
            "submit_a_ticket": "Submit a ticket",
            "subscribe": "Subscribe",
            "undo": "Undo",
            "unsubscribe": "Unsubscribe",
            "update": "Update",
            "upload": "Upload",
            "view": "View",
            "view_all": "View All",
            "view_more": "View More",
            "yes": "Yes"
        },
        "address": {
            "address_line_1": "Street and number",
            "address_line_2": "Apartment, unit, etc.",
            "city": "City",
            "province": "Province",
            "state": "State",
            "postal_code": "Postal Code",
            "zip_code": "Zip Code",
            "country": "Country",
            "latitude": "Latitude",
            "longitude": "Longitude"
        },
        "app": {
            "legal": {
                "privacy_policy": "Privacy Policy",
                "terms_of_service": "Terms of Service",
                "privacy": "Privacy",
                "terms": "Terms"
            },
            "required": "Required",
            "optional": "Optional",
            "about_us": "About Us",
            "find_us": "Find Us",
            "how_to_play": "How to Play",
            "faq": "FAQ",
            "contact_us": "Contact Us",
            "franchise": "Franchise",
            "jobs": "Jobs",
            "menu": "Menu",
            "order": "Order",
            "promotions": "Promotions",
            "contact_us_title": "Questions? Compliments? Opportunities?",
            "contact_us_description": "We value your feedback, inquiries, and suggestions. You can reach us through various means, and we're here to assist you in any way we can.",
            "contact_us_success": "Thank you for reaching out to us. We received your message! We'll get back to you as soon as possible."
        },
        "auth": {
            "failed": "These credentials do not match our records.",
            "password": "Password",
            "throttle": "Too many login attempts. Please try again in {seconds} seconds.",
            "my_account": "My Account",
            "my_profile": "My Profile",
            "welcome": "Welcome, {name}",
            "login": "Log In",
            "register": "Sign Up",
            "logout": "Logout",
            "social_login": "Continue with {social_media}",
            "or": "or",
            "name": "Name",
            "first_name": "First Name",
            "last_name": "Last Name",
            "email": "Email Address",
            "email_or_phone": "Email Address or Phone Number",
            "confirm_password": "Confirm Password",
            "forgot_password": "Forgot password",
            "current_password": "Current Password",
            "new_password": "New Password",
            "phone_number": "Phone Number",
            "remember_me": "Remember me",
            "new_user": "Don't have an account?",
            "existing_user": "Already have an account?",
            "almost_there": "You're almost there!",
            "reset_password": "Reset Password",
            "verify": "Verify Phone Number",
            "otp": "Enter the 6-digit code sent to you.",
            "newsletter": "I want to join the VIP list for exclusive access to special promotions and discounts.",
            "terms_and_conditions": {
                "agree": "By checking this box, I confirm that I have read and agree to the",
                "and": "and"
            }
        },
        "balance": {
            "credits": "Token|Tokens",
            "points": "Point|Points",
            "recent_transactions": "Recent Transactions",
            "deposit": "Deposit",
            "withdraw": "Withdraw"
        },
        "calendar": {
            "sunday": "Sunday",
            "monday": "Monday",
            "tuesday": "Tuesday",
            "wednesday": "Wednesday",
            "thursday": "Thursday",
            "friday": "Friday",
            "saturday": "Saturday"
        },
        "franchise": {
            "title": "Become our partner",
            "form": {
                "header": "We thank you for your interest in becoming a partner of {brand}. To learn more, fill out the form below.",
                "first_name": "First Name",
                "last_name": "Last Name",
                "date_of_birth": "Date of Birth",
                "address": "Address",
                "city": "City",
                "province": "Province",
                "postal_code": "Postal Code",
                "phone_number": "Phone Number",
                "business_phone_number": "Business Phone Number",
                "email": "Email",
                "current_business": "Describe your current business activities",
                "operator": {
                    "label": "Who will operate the restaurant?",
                    "yourself": "Yourself",
                    "other": "Someone else"
                },
                "languages": "Languages you're fluent in",
                "preferred_region": "Preferred Region",
                "2nd_preferred_region": "Second most preferred region",
                "hear_about_us": "How did you hear about us?",
                "submit": "Submit"
            },
            "success": "Your form was sent successfully."
        },
        "homepage": {
            "slogan": "Grab The Fun!",
            "description": "Make Your Birthday Unforgettable! Host Your Party with ClawMee Today - Fun, Games, and Prizes Await!",
            "cta": "Book Your Party Now!",
            "about_us_description": "At CläwMee, we're more than just an amusement center with claw machines; we're creators of unforgettable moments. Each visit to our store is a promise of excitement, laughter, and picture-perfect memories. We specialize in crafting experiences that bring smiles to your face and immortalize the joy of the moment. Join us for a whirlwind of fun, prizes, and memories at CläwMee!",
            "faq": {
                "title": "How much are the tokens?",
                "question": "How many tokens are needed to play your claw machines?",
                "answer": "The claw machines requires 3 to 7 tokens per play."
            },
            "how_to_play": {
                "title": "How to play our claw machines?",
                "buy_the_tokens": {
                    "name": "Buy the tokens",
                    "description": "Before you start a game, you will need to purchase tokens available at our counter."
                },
                "play_and_win": {
                    "name": "Play and win",
                    "description": "Let the party begin! Insert your tokens into a machine, aim by moving the joystick, press the button ONCE, and start collecting plushies! It is very easy to win!"
                },
                "keep_or_trade": {
                    "name": "Keep, Trade or Save",
                    "description": "You can keep your plushies from the machines or exchange them for other prizes available in the store!\n\n            Couldn't decide on what to exchange on the spot? Not a problem! You can save the points with your accounts for your next visit (tokens could be saved too!)."
                }
            }
        },
        "location": {
            "business_hours": "Business Hours",
            "get_directions": "Get Directions",
            "closed": "Closed",
            "closing_soon": "Closing Soon",
            "open": "Open",
            "open_at": "Open {datetime}",
            "open_until": "Open until {datetime}",
            "view_all_locations": "View all locations",
            "store_info": "Store Info",
            "find_us": "Find Us",
            "find_location": "Find a Location",
            "location_search": "Search by address, postal code or city"
        },
        "notification": {
            "credit_deposit": "[0,1] {amount} token has been deposited to your CläwMee account.|[2,*] {amount} tokens has been deposited to your CläwMee account.",
            "credit_withdraw": "[0,1] {amount} token has been withdrawn from your CläwMee account.|[2,*] {amount} tokens has been withdrawn from your CläwMee account.",
            "credit_balance": "[0,1] Your new balance is {balance} token.|[2,*] Your new balance is {balance} tokens.",
            "points_deposit": "[0,1] Congratulations, you've won {amount} point at CläwMee.|[2,*] Congratulations, you've won {amount} points at CläwMee.",
            "points_withdraw": "[0,1] Congratulations, you've redeemed {amount} point at CläwMee.|[2,*] Congratulations, you've redeemed {amount} points at CläwMee.",
            "points_balance": "[0,1] Your new balance is {balance} point.|[2,*] Your new balance is {balance} points.",
            "review_balance": "Review your rewards and balance at https://clawmee.com/me."
        },
        "order": {
            "totals": {
                "count": "Count",
                "subtotal": "Subtotal",
                "discount": "Discount",
                "delivery_fee": "Delivery Fee",
                "tax": "Tax",
                "tips": "Tips",
                "gift_card": "Gift Card",
                "total": "Total"
            },
            "summary": "Summary",
            "summary_report": "Summary Report",
            "order_details": "Order details",
            "order_summary": "Order Summary",
            "order_number": "Order Number",
            "order_no": "Order #",
            "date": "Date & Time",
            "type": "Type",
            "delivery_address": "Delivery Address",
            "delivery_address_2": "Apt# (Optional)",
            "new_delivery_address": "Add an address",
            "reorder": "Reorder",
            "menu": "Menu",
            "description": "Description",
            "price": "Price",
            "pieces": "pcs",
            "quantity": "Quantity",
            "qty": "Qty",
            "other": "Other",
            "amount": "Amount",
            "instructions": "Instructions"
        },
        "pagination": {
            "previous": "&laquo; Previous",
            "next": "Next &raquo;"
        },
        "passwords": {
            "reset": "Your password has been reset!",
            "sent": "We have emailed your password reset link!",
            "throttled": "Please wait before retrying.",
            "token": "This password reset token is invalid.",
            "user": "We can't find a user with that email address."
        },
        "payment": {
            "payment_method": "Payment Method",
            "cash": "Cash",
            "credit_card": "Credit Card",
            "debit_card": "Debit Card",
            "credit_debit": "Credit and Debit",
            "gift_card": "Gift Card",
            "pay_at_restaurant": "Pay at the restaurant",
            "paid_by": "Paid by",
            "balance_owed": "Balance Owed",
            "error": "An error has occurred while processing your payment. Please try again later.",
            "expired_card": "Your card is expired. Please contact your financial institution.",
            "card_declined": "Your card was declined by the issuer. Please contact your financial institution."
        },
        "status": {
            "received": "Received",
            "confirmed": "Confirmed",
            "ready": "Ready",
            "completed": "Completed",
            "cancelled": "Cancelled",
            "refunded": "Refunded",
            "payment_error": "Payment Error"
        },
        "tax": {
            "gst": "GST",
            "hst": "HST",
            "pst": "PST",
            "qst": "QST",
            "sales_tax": "Sales Tax",
            "state_tax": "State Tax",
            "taxes": "Taxes"
        },
        "validation": {
            "accepted": "The {attribute} must be accepted.",
            "accepted_if": "The {attribute} must be accepted when {other} is {value}.",
            "active_url": "The {attribute} is not a valid URL.",
            "after": "The {attribute} must be a date after {date}.",
            "after_or_equal": "The {attribute} must be a date after or equal to {date}.",
            "alpha": "The {attribute} must only contain letters.",
            "alpha_dash": "The {attribute} must only contain letters, numbers, dashes and underscores.",
            "alpha_num": "The {attribute} must only contain letters and numbers.",
            "array": "The {attribute} must be an array.",
            "before": "The {attribute} must be a date before {date}.",
            "before_or_equal": "The {attribute} must be a date before or equal to {date}.",
            "between": {
                "numeric": "The {attribute} must be between {min} and {max}.",
                "file": "The {attribute} must be between {min} and {max} kilobytes.",
                "string": "The {attribute} must be between {min} and {max} characters.",
                "array": "The {attribute} must have between {min} and {max} items."
            },
            "boolean": "The {attribute} field must be true or false.",
            "confirmed": "The {attribute} confirmation does not match.",
            "current_password": "The password is incorrect.",
            "date": "The {attribute} is not a valid date.",
            "date_equals": "The {attribute} must be a date equal to {date}.",
            "date_format": "The {attribute} does not match the format {format}.",
            "declined": "The {attribute} must be declined.",
            "declined_if": "The {attribute} must be declined when {other} is {value}.",
            "different": "The {attribute} and {other} must be different.",
            "digits": "The {attribute} must be {digits} digits.",
            "digits_between": "The {attribute} must be between {min} and {max} digits.",
            "dimensions": "The {attribute} has invalid image dimensions.",
            "distinct": "The {attribute} field has a duplicate value.",
            "email": "The {attribute} must be a valid email address.",
            "ends_with": "The {attribute} must end with one of the following: {values}.",
            "enum": "The selected {attribute} is invalid.",
            "exists": "The selected {attribute} is invalid.",
            "file": "The {attribute} must be a file.",
            "filled": "The {attribute} field must have a value.",
            "gt": {
                "numeric": "The {attribute} must be greater than {value}.",
                "file": "The {attribute} must be greater than {value} kilobytes.",
                "string": "The {attribute} must be greater than {value} characters.",
                "array": "The {attribute} must have more than {value} items."
            },
            "gte": {
                "numeric": "The {attribute} must be greater than or equal to {value}.",
                "file": "The {attribute} must be greater than or equal to {value} kilobytes.",
                "string": "The {attribute} must be greater than or equal to {value} characters.",
                "array": "The {attribute} must have {value} items or more."
            },
            "image": "The {attribute} must be an image.",
            "in": "The selected {attribute} is invalid.",
            "in_array": "The {attribute} field does not exist in {other}.",
            "integer": "The {attribute} must be an integer.",
            "ip": "The {attribute} must be a valid IP address.",
            "ipv4": "The {attribute} must be a valid IPv4 address.",
            "ipv6": "The {attribute} must be a valid IPv6 address.",
            "json": "The {attribute} must be a valid JSON string.",
            "lt": {
                "numeric": "The {attribute} must be less than {value}.",
                "file": "The {attribute} must be less than {value} kilobytes.",
                "string": "The {attribute} must be less than {value} characters.",
                "array": "The {attribute} must have less than {value} items."
            },
            "lte": {
                "numeric": "The {attribute} must be less than or equal to {value}.",
                "file": "The {attribute} must be less than or equal to {value} kilobytes.",
                "string": "The {attribute} must be less than or equal to {value} characters.",
                "array": "The {attribute} must not have more than {value} items."
            },
            "mac_address": "The {attribute} must be a valid MAC address.",
            "max": {
                "numeric": "The {attribute} must not be greater than {max}.",
                "file": "The {attribute} must not be greater than {max} kilobytes.",
                "string": "The {attribute} must not be greater than {max} characters.",
                "array": "The {attribute} must not have more than {max} items."
            },
            "mimes": "The {attribute} must be a file of type: {values}.",
            "mimetypes": "The {attribute} must be a file of type: {values}.",
            "min": {
                "numeric": "The {attribute} must be at least {min}.",
                "file": "The {attribute} must be at least {min} kilobytes.",
                "string": "The {attribute} must be at least {min} characters.",
                "array": "The {attribute} must have at least {min} items."
            },
            "multiple_of": "The {attribute} must be a multiple of {value}.",
            "not_in": "The selected {attribute} is invalid.",
            "not_regex": "The {attribute} format is invalid.",
            "numeric": "The {attribute} must be a number.",
            "password": "The password is incorrect.",
            "phone": "The {attribute} field must be a valid number.",
            "present": "The {attribute} field must be present.",
            "prohibited": "The {attribute} field is prohibited.",
            "prohibited_if": "The {attribute} field is prohibited when {other} is {value}.",
            "prohibited_unless": "The {attribute} field is prohibited unless {other} is in {values}.",
            "prohibits": "The {attribute} field prohibits {other} from being present.",
            "regex": "The {attribute} format is invalid.",
            "required": "The {attribute} field is required.",
            "required_array_keys": "The {attribute} field must contain entries for: {values}.",
            "required_if": "The {attribute} field is required when {other} is {value}.",
            "required_unless": "The {attribute} field is required unless {other} is in {values}.",
            "required_with": "The {attribute} field is required when {values} is present.",
            "required_with_all": "The {attribute} field is required when {values} are present.",
            "required_without": "The {attribute} field is required when {values} is not present.",
            "required_without_all": "The {attribute} field is required when none of {values} are present.",
            "same": "The {attribute} and {other} must match.",
            "size": {
                "numeric": "The {attribute} must be {size}.",
                "file": "The {attribute} must be {size} kilobytes.",
                "string": "The {attribute} must be {size} characters.",
                "array": "The {attribute} must contain {size} items."
            },
            "starts_with": "The {attribute} must start with one of the following: {values}.",
            "string": "The {attribute} must be a string.",
            "timezone": "The {attribute} must be a valid timezone.",
            "unique": "The {attribute} has already been taken.",
            "uploaded": "The {attribute} failed to upload.",
            "url": "The {attribute} must be a valid URL.",
            "uuid": "The {attribute} must be a valid UUID.",
            "custom": {
                "attribute-name": {
                    "rule-name": "custom-message"
                }
            },
            "attributes": []
        }
    },
    "fr": {
        "account": {
            "home": "Accueil",
            "profile": "Profil",
            "qr_code": "Code",
            "settings": "Réglages",
            "qr_header": "Gagnez et sauvegarder avec votre code.",
            "qr_description": "Montrez ce code QR à notre équipe pour accéder à votre compte.",
            "account_information": "Informations sur le compte",
            "update_account": "Mettre à jour les informations du profil de votre compte",
            "delete_account": "Supprimer le compte",
            "delete_account_disclaimer": "Une fois que votre compte est supprimé, la balance et les données de votre compte seront supprimés définitivement. Avant de supprimer votre compte, veuillez dépenser les points ou les jetons restants.",
            "delete_account_confirmation": "Êtes-vous certain de vouloir supprimer votre compte ?",
            "delete_account_instructions": "Une fois que votre compte est supprimé, la balance et les données de votre compte seront supprimés définitivement. Veuillez saisir le code à 6 chiffres envoyé sur votre téléphone pour confirmer que vous souhaitez supprimer définitivement votre compte."
        },
        "actions": {
            "accept": "Accepter",
            "add": "Ajouter",
            "agree": "Accepter",
            "apply": "Appliquer",
            "back": "Retour",
            "begin": "Début",
            "by": "par",
            "cancel": "Annuler",
            "change": "Modifier",
            "checkout": "Commander",
            "close": "Fermer",
            "collapse": "Réduire",
            "confirm": "Confirmer",
            "confirmation": {
                "delete": "Êtes-vous certains de vouloir supprimer? Cette action est permantente",
                "submit": "Confirmez la soumission?",
                "update": "Confirmez la mise à jour?"
            },
            "create": "Créer",
            "decline": "Refuser",
            "delete": "Supprimer",
            "disable": "Désactiver",
            "done": "Terminé",
            "download": "Télécharger",
            "edit": "Modifier",
            "enable": "Activer",
            "end": "Fin",
            "exit": "Quitter",
            "expand": "Étendre",
            "filter": "Filtrer",
            "find": "Trouver",
            "follow": "S'abonner",
            "go": "Aller",
            "help": "Aide",
            "hide": "Masquer",
            "hide_all": "Masquer tout",
            "home": "Accueil",
            "less": "Moins",
            "log_in": "Se connecter",
            "log_out": "Déconnecter",
            "manage": "Faire en sorte",
            "maximize": "Maximiser",
            "minimize": "Minimiser",
            "more": "Plus",
            "new": "Nouveau",
            "next": "Suivant",
            "no": "Non",
            "no_results": "Pas de résultats",
            "off": "Désactivé",
            "okay": "OK",
            "on": "Activé",
            "open": "Ouvrir",
            "other": "Autre",
            "pay": "Pay",
            "previous": "Précédent",
            "print": "Imprimer",
            "publish": "Publier",
            "recommend": "Recommander",
            "redo": "Rétablir",
            "register": "S'enregistrer",
            "remove": "Supprimer",
            "return": "Retour",
            "save": "Enregistrer",
            "search": "Rechercher",
            "select": "Sélectionner",
            "send": "Envoyer",
            "show": "Afficher",
            "show_all": "Afficher tout",
            "sign_up": "S'inscrire",
            "social_login": "Continuer avec {provider}",
            "start": "Début",
            "stop": "Arrêt",
            "submit": "Soumettre",
            "submit_a_ticket": "Soumettre un billet",
            "subscribe": "S'abonner",
            "undo": "Annuler",
            "unsubscribe": "Se désabonner",
            "update": "Mettre à jour",
            "upload": "Charger",
            "view": "Voir",
            "view_all": "Voir tout",
            "view_more": "Voir plus",
            "yes": "Oui"
        },
        "address": {
            "address_line_1": "Adresse",
            "address_line_2": "Appartement, unité, etc.",
            "city": "Ville",
            "province": "Province",
            "state": "État",
            "postal_code": "Code postal",
            "zip_code": "Code zip",
            "country": "Pays",
            "latitude": "Latitude",
            "longitude": "Longitude"
        },
        "app": {
            "legal": {
                "privacy_policy": "Politique de confidentialité",
                "terms_of_service": "Conditions d'utilisation",
                "privacy": "Confidentialité",
                "terms": "Conditions d'utilisation"
            },
            "required": "Requis",
            "optional": "Facultatif",
            "about_us": "À propos",
            "find_us": "Nous trouver",
            "how_to_play": "Comment jouer",
            "faq": "FAQ",
            "contact_us": "Contactez-nous",
            "franchise": "Franchise",
            "jobs": "Emplois",
            "menu": "Menu",
            "order": "Commander",
            "promotions": "Promotions",
            "contact_us_title": "Des questions ? Des compliments ? Des opportunités ?",
            "contact_us_description": "Nous apprécions vos commentaires, vos demandes et vos suggestions. Vous pouvez nous contacter par différents moyens, et nous sommes là pour vous aider de toutes les manières possibles.",
            "contact_us_success": "Merci de nous avoir contactés. Nous avons bien reçu votre message ! Nous vous répondrons dans les plus brefs délais."
        },
        "auth": {
            "failed": "Ces identifiants ne correspondent pas à nos enregistrements.",
            "password": "Mot de passe",
            "throttle": "Tentatives de connexion trop nombreuses. Veuillez essayer de nouveau dans {seconds} secondes.",
            "my_account": "Mon compte",
            "my_profile": "Mon Profil",
            "welcome": "Bienvenue, {name}",
            "login": "Se connecter",
            "register": "S'inscrire",
            "logout": "Déconnexion",
            "social_login": "Continuer avec {social_media}",
            "or": "ou",
            "name": "Nom",
            "first_name": "Prénom",
            "last_name": "Nom",
            "email": "Adresse courriel",
            "email_or_phone": "Adresse courriel ou Numéro de téléphone",
            "confirm_password": "Confirmez le mot de passe",
            "forgot_password": "Mot de passe oublié",
            "current_password": "Mot de passe actuel",
            "new_password": "Nouveau mot de passe",
            "phone_number": "Numéro de téléphone",
            "remember_me": "Se souvenir de moi",
            "new_user": "Nouvel utilisateur?",
            "existing_user": "Vous avez déjà un compte?",
            "almost_there": "Vous y êtes presque!",
            "reset_password": "Réinitialiser le mot de passe",
            "verify": "Verify Phone Number",
            "otp": "Entrez le code à 6 chiffres qui vous a été envoyé.",
            "newsletter": "Je souhaite m'inscrire sur la liste VIP pour bénéficier d'un accès exclusif aux promotions et rabais.",
            "terms_and_conditions": {
                "agree": "En cochant cette case, je confirme que j'ai lu et que j'accepte les",
                "and": "et la"
            }
        },
        "balance": {
            "credits": "Jeton|Jetons",
            "points": "Point|Points",
            "recent_transactions": "Transactions récentes",
            "deposit": "Dépôt",
            "withdraw": "Retrait"
        },
        "calendar": {
            "sunday": "Dimanche",
            "monday": "Lundi",
            "tuesday": "Mardi",
            "wednesday": "Mercredi",
            "thursday": "Jeudi",
            "friday": "Vendredi",
            "saturday": "Samedi"
        },
        "franchise": {
            "title": "Devenir notre partenaire",
            "form": {
                "header": "Nous vous remercions de votre intérêt à devenir partenaire avec {brand}. Pour en savoir plus, remplissez le formulaire ci-dessous.",
                "first_name": "Prénom",
                "last_name": "Nom de famille",
                "date_of_birth": "Date de naissance",
                "address": "Adresse",
                "city": "Ville",
                "province": "Province",
                "postal_code": "Code Postal",
                "phone_number": "Numéro de téléphone",
                "business_phone_number": "Numéro de téléphone de l'entreprise",
                "email": "Adresse courriel",
                "current_business": "Décrivez vos autres activités commerciales actuelles",
                "operator": {
                    "label": "Qui exploitera le restaurant?",
                    "yourself": "Vous-même",
                    "other": "Une autre personne"
                },
                "languages": "Langues (écrites et parlées)",
                "preferred_region": "Région préférée",
                "2nd_preferred_region": "Deuxième choix de région préférée",
                "hear_about_us": "Comment avez-vous entendu parlez de nous?",
                "submit": "Soumettre"
            },
            "success": "Votre formulaire a bien été envoyé"
        },
        "homepage": {
            "slogan": "Attrapez le bonheur!",
            "description": "Rendez votre anniversaire inoubliable ! Organisez votre fête avec ClawMee dès aujourd'hui - Amusement, jeux et prix sont au rendez-vous !",
            "cta": "Réservez votre fête dès maintenant !",
            "about_us_description": "Chez CläwMee, nous sommes plus qu'un centre de divertissement avec des machines à toutous ; nous sommes des créateurs de moments inoubliables. Chaque visite dans notre magasin est une promesse d’excitation, de rire et de souvenirs parfaits. Nous sommes spécialisés dans la création d’expériences qui vous font sourire et immortalisent la joie du moment. Rejoignez-nous pour un tourbillon de plaisir, de prix et de souvenirs chez CläwMee!",
            "faq": {
                "title": "Combien coûte les jetons?",
                "question": "Combien de jetons est requis pour jouer aux machines à toutous?",
                "answer": "Chaque machine requiert entre 3 à 7 jetons par partie."
            },
            "how_to_play": {
                "title": "Comment jouer à nos machines à toutous?",
                "buy_the_tokens": {
                    "name": "Achetez des jetons",
                    "description": "Avant de commencer une partie, vous devez acheter de jetons disponibles à notre comptoir."
                },
                "play_and_win": {
                    "name": "Jouez et gagnez",
                    "description": "Que la partie commence ! Insérez vos jetons dans une machine, visez en déplaçeant le joystick, appuyez sur le bouton UNE fois et commencez à collecter des toutous. C'est très facile de gagner !"
                },
                "keep_or_trade": {
                    "name": "Garder, échanger ou sauvegarder",
                    "description": "Vous pouvez garder vos toutous des machines ou les échanger contre d'autres prix disponibles dans la boutique !\n\n            Vous n'arrivez pas à vous décider sur quel(s) prix échanger ? Pas de problème ! Vous pouvez sauvegarder les points sur votre compte pour votre prochaine visite "
                }
            }
        },
        "location": {
            "business_hours": "Heures d'ouverture",
            "get_directions": "Obtenir l'itinéraire",
            "closed": "Fermé",
            "closing_soon": "Bientôt fermé",
            "open": "Ouvert",
            "open_at": "Ouvert {datetime}",
            "open_until": "Ouvert jusqu'à {datetime}",
            "view_all_locations": "Voir tous les emplacements",
            "store_info": "Info sur l'emplacement",
            "find_us": "Nous trouver",
            "find_location": "Trouver un emplacement",
            "location_search": "Recherche par adresse, code postal ou ville"
        },
        "notification": {
            "credit_deposit": "[0,1] {amount} jeton a été déposée dans votre compte CläwMee.|[2,*] {amount} jetons a été déposée dans votre compte CläwMee.",
            "credit_withdraw": "[0,1] {amount} jeton a été retiré de votre compte CläwMee.|[2,*] {amount} jetons a été retiré de votre compte CläwMee.",
            "credit_balance": "[0,1] Votre nouveau solde est de {balance} jeton.|[2,*] Votre nouveau solde est de {balance} jetons.",
            "points_deposit": "[0,1] Félicitations, vous avez gagné {amount} point à CläwMee.|[2,*] Félicitations, vous avez gagné {amount} points à CläwMee.",
            "points_withdraw": "[0,1] Félicitations, vous avez échangé {amount} point à CläwMee.|[2,*] Félicitations, vous avez échangé {amount} points à CläwMee.",
            "points_balance": "[0,1] Votre nouveau solde est de {balance} point.|[2,*] Votre nouveau solde est de {balance} points.",
            "review_balance": "Review your rewards and balance at https://clawmee.com/me."
        },
        "order": {
            "totals": {
                "count": "Compte",
                "subtotal": "Sous-total",
                "discount": "Rabais",
                "delivery_fee": "Frais de livraison",
                "tax": "Taxes",
                "tips": "Pourboire",
                "gift_card": "Carte-cadeau",
                "total": "Total"
            },
            "summary": "Sommaire",
            "summary_report": "Rapport sommaire",
            "order_details": "Détails de la commande",
            "order_summary": "Sommaire de la commande",
            "order_number": "Numéro de commande",
            "order_no": "Nº de commande",
            "date": "Date et l'heure",
            "type": "Type",
            "delivery_address": "Adresse de livraison",
            "delivery_address_2": "# d'apt (Optionnel)",
            "new_delivery_address": "Ajouter une adresse",
            "reorder": "Commander à nouveau",
            "menu": "Menu",
            "description": "Description",
            "price": "Prix",
            "pieces": "mcx",
            "quantity": "Quantité",
            "qty": "Qté",
            "other": "Autre",
            "amount": "Montant",
            "instructions": "Remarque"
        },
        "pagination": {
            "previous": "&laquo; Précédent",
            "next": "Suivant &raquo;"
        },
        "passwords": {
            "reset": "Votre mot de passe a été réinitialisé!",
            "sent": "Nous vous avons envoyé par email le lien de réinitialisation du mot de passe!",
            "throttled": "Veuillez patienter avant de réessayer.",
            "token": "Ce jeton de réinitialisation du mot de passe n'est pas valide.",
            "user": "Aucun utilisateur n'a été trouvé avec cette adresse email."
        },
        "payment": {
            "payment_method": "Mode de paiement",
            "cash": "Comptant",
            "credit_card": "Carte de crédit",
            "debit_card": "carte de débit",
            "credit_debit": "Crédit et Débit",
            "gift_card": "carte-cadeau ",
            "pay_at_restaurant": "Payer au restaurant",
            "paid_by": "Payé par",
            "balance_owed": "Solde dû",
            "error": "Une erreur s'est produite lors du traitement de votre paiement. Veuillez réessayer plus tard.",
            "expired_card": "Votre carte est expirée. Veuillez contacter votre institution financière.",
            "card_declined": "Votre carte a été refusée. Veuillez contacter votre institution financière."
        },
        "status": {
            "received": "Reçu",
            "confirmed": "Confirmé",
            "ready": "Prêt",
            "completed": "Complété",
            "cancelled": "Annulé",
            "refunded": "Remboursé",
            "payment_error": "Erreur de paiement"
        },
        "tax": {
            "gst": "TPS",
            "hst": "TVH",
            "pst": "TVP",
            "qst": "TVQ",
            "sales_tax": "Taxe de vente",
            "state_tax": "Taxe d'État",
            "taxes": "Taxes"
        },
        "validation": {
            "accepted": "Le champ {attribute} doit être accepté.",
            "accepted_if": "Le champ {attribute} doit être accepté quand {other} a la valeur {value}.",
            "active_url": "Le champ {attribute} n'est pas une URL valide.",
            "after": "Le champ {attribute} doit être une date postérieure au {date}.",
            "after_or_equal": "Le champ {attribute} doit être une date postérieure ou égale au {date}.",
            "alpha": "Le champ {attribute} doit contenir uniquement des lettres.",
            "alpha_dash": "Le champ {attribute} doit contenir uniquement des lettres, des chiffres et des tirets.",
            "alpha_num": "Le champ {attribute} doit contenir uniquement des chiffres et des lettres.",
            "array": "Le champ {attribute} doit être un tableau.",
            "before": "Le champ {attribute} doit être une date antérieure au {date}.",
            "before_or_equal": "Le champ {attribute} doit être une date antérieure ou égale au {date}.",
            "between": {
                "array": "Le tableau {attribute} doit contenir entre {min} et {max} éléments.",
                "file": "La taille du fichier de {attribute} doit être comprise entre {min} et {max} kilo-octets.",
                "numeric": "La valeur de {attribute} doit être comprise entre {min} et {max}.",
                "string": "Le texte {attribute} doit contenir entre {min} et {max} caractères."
            },
            "boolean": "Le champ {attribute} doit être vrai ou faux.",
            "confirmed": "Le champ de confirmation {attribute} ne correspond pas.",
            "current_password": "Le mot de passe est incorrect.",
            "date": "Le champ {attribute} n'est pas une date valide.",
            "date_equals": "Le champ {attribute} doit être une date égale à {date}.",
            "date_format": "Le champ {attribute} ne correspond pas au format {format}.",
            "declined": "Le champ {attribute} doit être décliné.",
            "declined_if": "Le champ {attribute} doit être décliné quand {other} a la valeur {value}.",
            "different": "Les champs {attribute} et {other} doivent être différents.",
            "digits": "Le champ {attribute} doit contenir {digits} chiffres.",
            "digits_between": "Le champ {attribute} doit contenir entre {min} et {max} chiffres.",
            "dimensions": "La taille de l'image {attribute} n'est pas conforme.",
            "distinct": "Le champ {attribute} a une valeur en double.",
            "email": "Le champ {attribute} doit être une adresse e-mail valide.",
            "ends_with": "Le champ {attribute} doit se terminer par une des valeurs suivantes : {values}",
            "enum": "Le champ {attribute} sélectionné est invalide.",
            "exists": "Le champ {attribute} sélectionné est invalide.",
            "file": "Le champ {attribute} doit être un fichier.",
            "filled": "Le champ {attribute} doit avoir une valeur.",
            "gt": {
                "array": "Le tableau {attribute} doit contenir plus de {value} éléments.",
                "file": "La taille du fichier de {attribute} doit être supérieure à {value} kilo-octets.",
                "numeric": "La valeur de {attribute} doit être supérieure à {value}.",
                "string": "Le texte {attribute} doit contenir plus de {value} caractères."
            },
            "gte": {
                "array": "Le tableau {attribute} doit contenir au moins {value} éléments.",
                "file": "La taille du fichier de {attribute} doit être supérieure ou égale à {value} kilo-octets.",
                "numeric": "La valeur de {attribute} doit être supérieure ou égale à {value}.",
                "string": "Le texte {attribute} doit contenir au moins {value} caractères."
            },
            "image": "Le champ {attribute} doit être une image.",
            "in": "Le champ {attribute} est invalide.",
            "in_array": "Le champ {attribute} n'existe pas dans {other}.",
            "integer": "Le champ {attribute} doit être un entier.",
            "ip": "Le champ {attribute} doit être une adresse IP valide.",
            "ipv4": "Le champ {attribute} doit être une adresse IPv4 valide.",
            "ipv6": "Le champ {attribute} doit être une adresse IPv6 valide.",
            "json": "Le champ {attribute} doit être un document JSON valide.",
            "lt": {
                "array": "Le tableau {attribute} doit contenir moins de {value} éléments.",
                "file": "La taille du fichier de {attribute} doit être inférieure à {value} kilo-octets.",
                "numeric": "La valeur de {attribute} doit être inférieure à {value}.",
                "string": "Le texte {attribute} doit contenir moins de {value} caractères."
            },
            "lte": {
                "array": "Le tableau {attribute} doit contenir au plus {value} éléments.",
                "file": "La taille du fichier de {attribute} doit être inférieure ou égale à {value} kilo-octets.",
                "numeric": "La valeur de {attribute} doit être inférieure ou égale à {value}.",
                "string": "Le texte {attribute} doit contenir au plus {value} caractères."
            },
            "mac_address": "Le champ {attribute} doit être une adresse MAC valide.",
            "max": {
                "array": "Le tableau {attribute} ne peut contenir plus de {max} éléments.",
                "file": "La taille du fichier de {attribute} ne peut pas dépasser {max} kilo-octets.",
                "numeric": "La valeur de {attribute} ne peut être supérieure à {max}.",
                "string": "Le texte de {attribute} ne peut contenir plus de {max} caractères."
            },
            "mimes": "Le champ {attribute} doit être un fichier de type : {values}.",
            "mimetypes": "Le champ {attribute} doit être un fichier de type : {values}.",
            "min": {
                "array": "Le tableau {attribute} doit contenir au moins {min} éléments.",
                "file": "La taille du fichier de {attribute} doit être supérieure à {min} kilo-octets.",
                "numeric": "La valeur de {attribute} doit être supérieure ou égale à {min}.",
                "string": "Le texte {attribute} doit contenir au moins {min} caractères."
            },
            "multiple_of": "La valeur de {attribute} doit être un multiple de {value}",
            "not_in": "Le champ {attribute} sélectionné n'est pas valide.",
            "not_regex": "Le format du champ {attribute} n'est pas valide.",
            "numeric": "Le champ {attribute} doit contenir un nombre.",
            "password": "Le mot de passe est incorrect",
            "phone": "Le champ {attribute} doit être un numéro valide.",
            "present": "Le champ {attribute} doit être présent.",
            "prohibited": "Le champ {attribute} est interdit.",
            "prohibited_if": "Le champ {attribute} est interdit quand {other} a la valeur {value}.",
            "prohibited_unless": "Le champ {attribute} est interdit à moins que {other} est l'une des valeurs {values}.",
            "prohibits": "Le champ {attribute} interdit {other} d'être présent.",
            "regex": "Le format du champ {attribute} est invalide.",
            "required": "Le champ {attribute} est obligatoire.",
            "required_array_keys": "The {attribute} field must contain entries for: {values}.",
            "required_if": "Le champ {attribute} est obligatoire quand la valeur de {other} est {value}.",
            "required_unless": "Le champ {attribute} est obligatoire sauf si {other} est {values}.",
            "required_with": "Le champ {attribute} est obligatoire quand {values} est présent.",
            "required_with_all": "Le champ {attribute} est obligatoire quand {values} sont présents.",
            "required_without": "Le champ {attribute} est obligatoire quand {values} n'est pas présent.",
            "required_without_all": "Le champ {attribute} est requis quand aucun de {values} n'est présent.",
            "same": "Les champs {attribute} et {other} doivent être identiques.",
            "size": {
                "array": "Le tableau {attribute} doit contenir {size} éléments.",
                "file": "La taille du fichier de {attribute} doit être de {size} kilo-octets.",
                "numeric": "La valeur de {attribute} doit être {size}.",
                "string": "Le texte de {attribute} doit contenir {size} caractères."
            },
            "starts_with": "Le champ {attribute} doit commencer avec une des valeurs suivantes : {values}",
            "string": "Le champ {attribute} doit être une chaîne de caractères.",
            "timezone": "Le champ {attribute} doit être un fuseau horaire valide.",
            "unique": "La valeur du champ {attribute} est déjà utilisée.",
            "uploaded": "Le fichier du champ {attribute} n'a pu être téléversé.",
            "url": "Le format de l'URL de {attribute} n'est pas valide.",
            "uuid": "Le champ {attribute} doit être un UUID valide",
            "custom": {
                "attribute-name": {
                    "rule-name": "custom-message"
                }
            },
            "attributes": []
        }
    }
}
